.container3 {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
.img2 {
  width: 50%;
  object-fit: cover;
  border-radius: 60px;
}
.img3 {
  width: 70%;
  border-radius: 60px;
  margin-left: 28px;
  margin-right: 28px;
}
.img4 {
  width: 50%;
  object-fit: cover;
  border-radius: 60px;
}
