.container4 {
  display: flex;
  flex-direction: column;
  padding: 50px;
  background-color: #f2f5f7;
}
.matn1 {
  display: flex;
  flex-direction: row;
  padding: 50px;
}
.matn2 {
  display: flex;
  flex-direction: row;
  padding: 50px;
}
.title1 {
  width: 30%;
  color: #47467a;
}

.list-container {
  display: flex;
  flex-direction: column;
}
.list-container2 {
  display: flex;
  flex-direction: column;
  margin-left: 80px;
}
.list-container3 {
  display: flex;
  flex-direction: column;
  margin-left: 100px;
}
.list2 {
  width: 70%;
  text-align: left;
  display: flex;
  flex-direction: column;
  margin-left: 300px;
  margin-bottom: 40px;
}
.title2 {
  margin-bottom: 10px;
  color: #47467a;
}
.title3 {
  margin-bottom: 30px;
  color: #47467a;
}
