.container2 {
  display: flex;
  flex-direction: row;
  margin-top: -50px;
  height: 100vh;
  padding: 10px;
}
.text1 {
  text-align: left;
  width: 60%;
  font-size: 25px;
  line-height: 30px;
}
.list1 {
  margin-top: 190px;
  text-align: left;
  margin-left: 200px;
  line-height: 30px;
}
li {
  list-style: none;
}
